<template>
  <section class="signin fill-height">
    <v-container py-0 fill-height>
      <v-layout row wrap align-center>
        <v-flex md6 offset-md3 lg4 offset-lg4>
          <v-tabs v-model="active">
            <v-tab :key="0">
              Via NTFU
            </v-tab>
            <v-tab :key="1">
              Via inlogcode
            </v-tab>
            <v-tab-item :key="0">
              <v-card flat>
                <v-card-text class="pa-4">
                  <p>Inloggen met je NTFU-account.</p>
                  <v-btn :href="loginUrl" color="secondary px-4 mx-0" depressed>Ga verder</v-btn>
                </v-card-text>
              </v-card>
            </v-tab-item>
            <v-tab-item :key="1">
              <v-card flat>
                <v-card-text class="pa-4">
                  <p>Inloggen met de inlogcodes die je hebt ontvangen van een beheerder.</p>
                  <v-btn :href="loginUrlVolunteer" color="secondary px-4 mx-0" depressed>Ga verder</v-btn>
                </v-card-text>
              </v-card>
            </v-tab-item>
          </v-tabs>
        </v-flex>
      </v-layout>
    </v-container>
  </section>
</template>

<script>
import {mapState} from 'vuex';
import formMixin from '~/plugins/mixins/form';

export default {
  mixins: [formMixin],
  data() {
    return {
      form: {
        email: '',
        password: '',
        remember: false,
      },
      active: null,
    };
  },
  computed: mapState({
    loginUrl: state => state.auth.loginUrl,
    loginUrlVolunteer: state => state.auth.loginUrlVolunteer,
  }),
  methods: {
    async submit() {
      try {
        await this.$auth.loginWith('local', {
          data: this.form,
        });

        this.$router.push({path: '/toertochten'});
      } catch (e) {
        this.focusFirstError(this);
      }
    },
    next() {
      const active = parseInt(this.active);
      this.active = active < 1 ? active + 1 : 0;
    },
  },
};
</script>

<style lang="stylus" scoped>
  @import '~assets/style/colors'

  .signin

    >>> .theme--light.v-tabs__bar
      background transparent

      .v-tabs__slider
        height 0 !important

      .v-tabs__item, .v-tabs__item--active
        font-weight bold
        padding 6px 25px
        margin-right 2px
        border-radius 3px 3px 0 0
        background white-lighten-3

      .v-tabs__item--active
        background white
        color secondary

</style>
