<template>
  <v-layout row wrap>
    <v-flex xs12>
      <sign-in/>
    </v-flex>
  </v-layout>
</template>

<script>
import SignIn from '~/components/SignIn';

export default {
  auth: false,
  components: {
    SignIn,
  },
  middleware: 'guest',
  asyncData({store}) {
    store.commit('page/setMeta', {
      title: 'Inloggen',
    });
  },
  head: {
    title: 'Inloggen',
  },
};
</script>
