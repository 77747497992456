export default {
  methods: {
    focusFirstError(component, offset = -240) {
      component.$nextTick(() =>
        component.$vuetify.goTo(component.$el.querySelector('.error--text'), {
          offset,
        }),
      );
    },
  },
};
